// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 4000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = true;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

const sidebarCards = true // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

$('.mainCallToAction').remove()

$('.adminBar, .adminBarEdit').insertBefore('#pageHeader')

// Homepage things
if ($('body').hasClass('homepage')) {
  // Re-order home elements
  $('.homeFeedBox1').insertAfter('.homeFeatures')
  $('.homefeaturecategory-homeboxfullwidth').insertAfter('.homefeaturecategory-homeboxquickgiving')
  // Shift event feed item dates into position
  $('.homeFeedevents .feedItem').each(function () {
    $(this).find('.associatedStartDate').insertAfter($(this).find('.home-feed-image-link img'))
  })
  // Size stats
  $(window).on('load resize', function () {
    $('.homefeaturecategory-homeboximpactstats tr').each(function () {
      $(this).attr('style', `height: ${$(this).css('width')} !important`)
    })
  })
  // Custom homepage quick giving image sizing
  $('.quickGivingPanel .donationAmount img').each(function () {
    $(this).attr('src', $(this).attr('src').replace('&w=300&h=90', '&w=904&h=462'))
  })
  // Wrap feed intros
  $('.homeFeed:not(.homeFeedBox1)').each(function () {
    const $thisSnippetWrapper = $('<li class="feedItem feedIntroSnippet"></div>')
    $(this).find('.feedList').prevAll().each(function () {
      $(this).prependTo($thisSnippetWrapper)
    })
    $thisSnippetWrapper.prependTo($(this).find('.feedList'))
    const thisFeed = $(this).find('.feedSeeMore')
    const seeMoreURL = thisFeed.find('.cta-button').attr('href')
    thisFeed.attr('onclick', `window.location="${seeMoreURL}"`)
  })
}

$('.NewsletterSign-up > *').wrapAll('<span class="newsletterInner"></span>')

// Different implementation of moving subsite menu into main nav area
if ($('body').hasClass('subsite')) {
  $('#menuMain ul.topLevel').html($('#menuSub ul.topLevel').html())
  $('#menuSub').remove()
  if ($('body').hasClass('homepage')) {
    $(`
      <div class="carouselDetailWrapper">
        <div class="carouselSlideDetail">
          <h2 class="carouselSlideHeading">
            <a>${$('#h1SubsiteTitle').text()}</a>
          </h2>
        </div>
      </div>
    `).insertAfter('.carouselSlideTitle')
  }
  $('<a href="/" class="back-to-main-site">← Back to main site</a>').insertAfter('.mainLogo')
}

$(window).on('load resize scroll', function (e) {
  $('.back-to-main-site').css('left', $('.mainLogo').get(0).getBoundingClientRect().right)
})

$('.listedEvent').each(function () {
  $(this).find('.associatedStartDate').appendTo($(this).find('.listed-post-image-link'))
})

if (!$('body').hasClass('subsite')) {
  const extraLinks = $('<div class="extraMenuItemsWrapper"><li class="menuBasketLinkListItem"><a class="menuExtra menuBasketLink" href="/checkout">Basket</a></li><li class="menuProfileLinkListItem"><a class="menuExtra menuProfileLink" href="/my-details">Profile</a></li></div>')
  $(extraLinks).insertBefore(('.level1 > li:first-child'))
}

// Reposition breadcrumb
if ($('body').hasClass('listing-with-feature')) {
  $('.carouselSlideHeading').each(function(){
    $('.headerText .breadcrumbWrapper').clone().insertBefore(this);
  })
}

if ($('body').hasClass('listing-without-feature')) {
  $('.carouselSlide').each(function(){
    $('.headerText .breadcrumbWrapper').clone().insertBefore(this);
  })
}